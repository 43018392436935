import { Spinner, Stack } from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import * as Environment from './core/actions/environment-actions';
import * as Workplace from './core/actions/workplace-actions';
import { IStore } from './core/store';
import { IWorkplace } from '@piceasoft/core';
import MainRouter from './routers';

interface MapStateProps {
    isReady: boolean
    isAuthenticated: boolean
    usePicea: boolean
    workplace: IWorkplace
}

interface MapDispatchProps {
    requestInitialization: () => void
    requestWorkplace: () => void
    requestPiceasoft: () => void
    requestSupport: (jivo_id?: string) => void
}

interface OwnProps {
    spinnerLabel: string
}

type StartupProps = MapStateProps & MapDispatchProps & OwnProps

const Startup: React.FC<StartupProps> = (props) => {

    React.useEffect(() => {
        props.requestInitialization();
    }, [props.isReady]);

    React.useEffect(() => {
        if (props.isAuthenticated) {
            props.requestWorkplace();
        }
    }, [props.isAuthenticated]);

    React.useEffect(() => {
        if (props.isReady && props.usePicea) {
            props.requestPiceasoft();
        }
    }, [props.workplace, props.isReady]);

    React.useEffect(() => {
        if (props.workplace.options?.support?.enabled) {
            props.requestSupport(props.workplace.options?.support.jivo_id);
        }
    }, [props.workplace]);

    if (props.isReady) return <MainRouter isAuthenticated={props.isAuthenticated} />

    return (
        <Stack verticalAlign="center" horizontalAlign="center" grow={1}>
            <Spinner
            // label={props.spinnerLabel}
            />
        </Stack>
    )
}

export default connect<MapStateProps, MapDispatchProps, OwnProps, IStore>(
    (state: IStore): MapStateProps => ({
        isAuthenticated: state.environment.isAuthenticated,
        isReady: state.environment.isReady,
        usePicea: state.environment.picea !== undefined,
        workplace: state.workplace
    }),
    {
        requestInitialization: Environment.actionCreators.initializationRequest,
        requestWorkplace: Workplace.actionCreators.requestWorkplace,
        requestPiceasoft: Environment.actionCreators.requestPiceasoft,
        requestSupport: Environment.actionCreators.requestSupport
    }
)(Startup);
