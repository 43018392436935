import { Action, Reducer } from "redux";
import { KnownAction } from "../actions/environment-actions";
import { PiceaStatuses, IEnvironment, PiceaConnectionStatuses } from '@piceasoft/core';

export const reducer: Reducer<IEnvironment> = (state: IEnvironment | undefined, incomingAction: Action): IEnvironment => {
    if (state === undefined) {
        return {
            name: "unknown",
            version: "unknown",
            isAuthenticated: false,
            isReady: false,
            lang: "ru",
            culture: "ru",
            jivo: undefined
        };
    }
    const action = incomingAction as KnownAction;
    let newState = { ...state }
    switch (action.type) {
        case 'ENVIRONMENT_INITIALIZATION_REQUEST':
            return { ...state, isReady: false };
        case 'ENVIRONMENT_INITIALIZATION_IS_READY':
            return { ...action.configuration, isReady: true };
        case 'ENVIRONMENT_RECEIVE_JSAPILINK':
            // accept script only if not already got
            if (!newState.picea) {
                newState.picea = {
                    status: PiceaStatuses.Missing,
                    script: action.script,
                    connections: {
                        otf: {
                            status: PiceaConnectionStatuses.Disconnected
                        },
                        ncd: {
                            status: PiceaConnectionStatuses.Disconnected
                        },
                        usb: {
                            status: PiceaConnectionStatuses.Disconnected
                        }
                    }
                } 
            }
            else {
                return state;
            }
            return newState;
        case 'ENVIRONMENT_PICEA_RECEIVE_STATUS':
            if (!newState.picea) return state;
            newState.picea = { ...newState.picea }
            newState.picea.status = action.status;
            return newState;
        case 'ENVIRONMENT_PICEA_RECEIVE_CONNECTION_ID':
            if (!newState.picea) return state;
            newState.picea = { ...newState.picea }
            newState.picea.connection_id = action.connection_id;
            return newState;
        case 'ENVIRONMENT_PICEA_RECEIVE_GLOBAL_ERROR':
            if (!newState.picea) return state;
            newState.picea = { ...newState.picea }
            newState.picea.status = PiceaStatuses.Fail;
            newState.picea.error = action.error;
            return newState;
        case 'ENVIRONMENT_PICEA_RECEIVE_QR_CODE':
            if (!newState.picea) return state;
            newState.picea = { ...newState.picea }
            newState.picea.qr = action.link;
            return newState;
        case 'ENVIRONMENT_PICEA_RECEIVE_LINK':
            if (!newState.picea) return state;
            newState.picea = { ...newState.picea }
            newState.picea.link = action.link;
            return newState;
        case 'ENVIRONMENT_PICEA_RECEIVE_INSTALLER':
            if (!newState.picea) return state;
            newState.picea = { ...newState.picea }
            newState.picea.installer = action.link;
            return newState;
        case 'ENVIRONMENT_PICEA_CHANGE_OTF_CONNNECTION':
            if (!state.picea) return state;
            return {
                ...state,
                picea: {
                    ...state.picea,
                    connections: {
                        ...state.picea.connections,
                        otf: {
                            ...state.picea.connections.otf,
                            status: action.status,
                            error: action.error
                        }
                    }
                }
            }

        case 'ENVIRONMENT_PICEA_CHANGE_USB_CONNNECTION':
            if (!state.picea) return state;
            return {
                ...state,
                picea: {
                    ...state.picea,
                    connections: {
                        ...state.picea.connections,
                        usb: {
                            ...state.picea.connections.usb,
                            status: action.status,
                            error: action.error
                        }
                    }
                }
            }

        case 'ENVIRONMENT_PICEA_CHANGE_NCD_CONNNECTION':
            if (!state.picea) return state;
            return {
                ...state,
                picea: {
                    ...state.picea,
                    connections: {
                        ...state.picea.connections,
                        ncd: {
                            ...state.picea.connections.ncd,
                            status: action.status,
                            error: action.error
                        }
                    }
                }
            }

        case 'ENVIRONMENT_PICEA_SET_USB_CONNECTOR_VERSION':
            if (!state.picea) return state;
            return {
                ...state,
                picea: {
                    ...state.picea,
                    connections: {
                        ...state.picea.connections,
                        usb: {
                            ...state.picea.connections.usb,
                            version: action.version,
                        }
                    }
                }
            }

        default: return state;
    }
};
