import { getTheme, IStackStyles, Spinner, Stack } from '@fluentui/react';
import * as React from 'react';
import { Notifications } from '../shared/notifications/Notifications';
import { useSelector } from 'react-redux';
import { IStore } from '../../core/store';
import Footer from './footer/Footer';
import Header from './header/Header';
import Main from './main/Main';
import { ItemsNotFound } from '../shared/notFound/ItemsNotFound';
import { strings } from '../../localization/strings';
import { PiceaStatuses } from '@piceasoft/core';
import { serverErrorDescriber, titleDescriber } from '../../core/scripts/picea.errors';

const theme = getTheme();
const headerStyles: IStackStyles = { root: { zIndex: 10, position: "relative" } };
const mainStyles: IStackStyles = { root: { position: "relative", backgroundColor: theme.palette.neutralLighterAlt } };
const footerStyles: IStackStyles = { root: { zIndex: 10, position: "relative" } };

const Layout: React.FC = (props) => {
    const organization = useSelector((state: IStore) => state.workplace.organization);
    const pointEnabled = useSelector((state: IStore) => state.workplace.point?.enabled);
    const picea = useSelector((state: IStore) => state.environment.picea);
    return (
        <Stack grow={1}>
            <Stack.Item styles={headerStyles}>
                <Header logo={organization?.logo} organization={organization?.name} />
            </Stack.Item>
            <Stack.Item verticalFill styles={mainStyles}>
                {picea?.status === PiceaStatuses.Fail && picea.error?.status && (
                    <ItemsNotFound 
                        info={titleDescriber(picea.error.status)}
                        suggestion={serverErrorDescriber(picea.error.status, picea.error.message)}
                        doNotUseButton
                        imgHeight={300}
                        imgSrc={"images/placeholders/security_crop.png"}
                    />
                ) || (
                    pointEnabled === undefined && (
                        <Stack verticalFill horizontalAlign="center" verticalAlign="center">
                            <Spinner />
                        </Stack>
                    ) || (
                        pointEnabled && (
                            <Main>{props.children}</Main>
                        ) || (
                            <ItemsNotFound backgroundColor={theme.palette.whiteTranslucent40} doNotUseButton
                                info={strings.POINT_LOCKED.INFO}
                                imgHeight={300}
                                suggestion={strings.POINT_LOCKED.SUGGESTION}
                                imgSrc={"images/placeholders/security_crop.png"} />
                        )
                    )
                )}
            </Stack.Item>
            <Stack.Item styles={footerStyles}>
                <Footer />
            </Stack.Item>
            <Notifications />
        </Stack>
    );
}

export default Layout;
