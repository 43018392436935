import { CommandBarButton, DirectionalHint, FontIcon, Stack, Text } from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators } from '../../../core/actions/teaching-actions';
import { consoleStyles } from '../../../core/scripts/console';
import { IStore } from '../../../core/store';
import { strings } from '../../../localization/strings';
import { StartTeachingItem } from '../../shared/startTeaching/StartTeachingItem';

const Support: React.FC<{ label?: string }> = ({ label }) => {

    const workplace = useSelector((s: IStore) => s.workplace);
    const process = useSelector((s: IStore) => s.process.current);
    const transaction = useSelector((s: IStore) => s.process.current?.transaction);
    const teachingState = useSelector((s: IStore) => s.teaching)
    const supportButtonId = useId('footer-support-button-id')
    const teachingItemName = "support"
    const dispatch = useDispatch();

    React.useEffect(() => {
        setTimeout(() => {
            dispatch(actionCreators.addItemId(teachingItemName, supportButtonId))
        }, 500)
    }, [])

    const support = () => {
        if (window.jivo_api) {
            const contact = {
                "name": workplace.point?.code,
                "description": workplace.organization?.name + " : " + workplace.company?.name + " : " + workplace.point?.code,
            }
            let data: Array<{ title: string, content?: string, link?: string }> = []

            data.push({ title: "Юр. лицо", content: workplace.company?.name });
            data.push({ title: "Магазин", content: workplace.point?.name });

            if (process && process.id > 0) {
                data.push({ title: "PID", content: process.id.toString() });
            }

            if (transaction && transaction.number && transaction.uid) {
                data.push({ title: strings.FOOTER.SUPPORT.TRANSACTION, content: transaction.number, link: `https://one2phone.ru/Services/Request/${transaction.uid}` });
            }

            console.debug("%c" + "JIVO: jivo_api.setContactInfo & jivo_api.setCustomData", consoleStyles.logic);
            console.info({ contact, data });

            window.jivo_api.setContactInfo(contact);
            window.jivo_api.setCustomData(data);
            window.jivo_api.open({ start: 'chat' });
        }
    }

    return (
        <Stack horizontal>
            <CommandBarButton id={supportButtonId} onClick={() => { support(); }} style={{ height: 28, padding: "0 8px" }}>
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                    <FontIcon iconName="Headset" />
                    <Text variant="small">{label ?? strings.FOOTER.SUPPORT.TITLE}</Text>
                </Stack>
            </CommandBarButton>
            {teachingState.currentItem === teachingItemName && (
                <StartTeachingItem
                    name={teachingItemName}
                    header={strings.START_TEACHING.SUPPORT.HEADER}
                    text={strings.START_TEACHING.SUPPORT.TEXT}
                    target={supportButtonId}
                    direction={DirectionalHint.topCenter}
                />
            )}
        </Stack>
    )
}

export default Support
